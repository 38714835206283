import type { ForwardedRef } from 'react';

export default function fillRef<T>(
  node: T | null,
  ...refs: Array<ForwardedRef<T> | undefined>
): void {
  if (refs.length === 0) return;
  for (const ref of refs) {
    if (!ref) continue;
    if (typeof ref === 'function') {
      ref(node);
    } else if (typeof ref === 'object') {
      ref.current = node;
    }
  }
}
