export const buttonAnimation = {
  whileHover: {
    filter: 'contrast(0.7)',
  },
  whileTap: {
    scale: 0.8,
  },
};

export const jackInTheBox = {
  initial: {
    opacity: 0,
    transformOrigin: 'center bottom',
    scale: '0.1',
    rotate: '30deg',
  },
  animate: {
    opacity: 1,
    rotate: ['-10deg', '3deg'],
    scale: 1,
    duration: {
      ease: 'easeOut',
      duration: 0.15,
    },
  },
  exit: {
    opacity: 0,
    transformOrigin: 'center bottom',
    scale: '0.1',
    rotate: '30deg',
  },
};

export const shimmer = {
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
  transition: { duration: 2, ease: 'linear', repeat: Number.POSITIVE_INFINITY },
  variants: {
    initial: {
      translateX: '-100%',
    },
    animate: {
      translateX: '100%',
    },
    exit: {
      translateX: '-100%',
    },
  },
};
