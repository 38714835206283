export const EXAM_US_URL = process.env.NEXT_PUBLIC_EXAM_US_URL ?? 'https://exam.flyer.us/exam';
export const EXAM_VN_URL = process.env.NEXT_PUBLIC_EXAM_VN_URL ?? 'https://exam.flyer.vn/exam';

const ROUTES = {
  HOME: '/',
  IELTS_SPEAKING_CHECK_POINT_LIST: '/ielts/speaking',
  IELTS_SPEAKING_CHECK_POINT: (id: string) => `/ielts/speaking/${id}`,
  HISTORY_WRITING: '/history',
  WRITE_CHECK_POINT: (id: string) => `/write/${id}`,
  WRITE_RESULT_OVERVIEW: (id: string) => `/write/result/${id}/overview`,
  WRITE_RESULT: (id: string) => `/write/result/${id}`,
  PROFILE: '/profiles',
  CREATE_PROFILE: '/create-profile',
  DIAMONDS_PREVIEW: '/diamonds-preview',
  FLYER_PACKAGE: '/flyer-package',
  PARTNER: `/partner`,
  IELTS_SPEAKING_RESULT: (attemptId: string) => `/ielts/speaking/result/${attemptId}`,
  PARTNER_RESULT_REVIEW_URL: (id: string) => `/partner/give-score/${id}`,

  SIGN_IN: (callback = '/') => {
    return `/login?auth_redirect_url=${callback}`;
  },
  SIGN_UP: (callback = '/') => {
    return `/signup?auth_redirect_url=${callback}`;
  },
  // LOGIN_URL_REDIRECT: (isUs: boolean, path: string) => {
  //   const baseUrl = isUs ? EXAM_US_URL : EXAM_VN_URL;
  //   const redirectUrl = isUs ? `${window.location.href}${path}` : path;
  //   return `${baseUrl}/login?auth_redirect_url=${redirectUrl}`;
  // },
  RECOVER: `/recover`,
  VERIFY_ACCOUNT: (callback = '/') => {
    return `/verify-account?auth_redirect_url${callback}`;
  },

  JOIN_CHALLENGE: (id: string) => `/join-challenge/${id}`,
  JOIN_CREATE_CHALLENGE: '/create-challenge',
  CREATE_CHALLENGE: (checkPointId: string, testLevelId: string) =>
    `/create-challenge/?cp=${checkPointId}&lv=${testLevelId}`,

  HISTORY_EXAM: `/history`,
  HISTORY_RANKING: `/ranking`,
  MISSION: `/mission`,
  MY_SCHOOL: `/my-school`,
  SPEAKING_REVIEW: `/exam/review`,
  EDIT_PROFILE: '/edit-profile',
  TEST_LEVEL_EXAM: (slug: string) => `/testlevels/${slug}`,

  REDIRECT_TO_CHECKPOINT: (checkPointId: string) => `/check-point/${checkPointId}`,
  REDIRECT_TO_CHECKPOINT_KET_PET: (checkPointId: string) =>
    `/speaking-kp/checkpoint/${checkPointId}`,
  REDIRECT_TO_TEST_LEVEL: (id: string, t = '') => `/test-level${t ? `-${t}` : ''}/${id}`,
  REDIRECT_TO_TEST_RESULT: (id: string) => `/test-results/${id}`,
  REDIRECT_TO_TEST_RESULT_OVERVIEW: (id: string) => `/test-results/${id}/result`,
  CHECKOUT: (years: number) =>
    `https://checkout.flyer.vn/tai-khoan-flyer-${years}-nam?checkout_config_id=10359`,
  PRACTICE_PREVIEW: `/exam/practice/preview`,

  PRACTICE: (exerciseId: string) => `/practice/${exerciseId}`,
  EDIT_PRACTICE: (exerciseId: string) => `/creator/${exerciseId}/edit`,
  LIBRARY: '/libraries',
  LIBRARY_WITH_PARAMS: (params: string | null = null) => `/libraries?${params || ''}`,

  MOBILE_CHECKPOINT: (checkPointId: string) => `/m/check-points/${checkPointId}`,
  MOBILE_RESULT: (resultId: string) => `/m/results/${resultId}`,
  MOBILE_REVIEW_RESULT: (resultId: string) => `/m/results/${resultId}/review`,

  CHECK_POINT_START: (id: string) => `/check-point/${id}/start`,
  CODE: '/codes',
} as const;

export const ORIGINAL_DOMAINS = [
  'www',
  'dev',
  'idev',
  'staging',
  'exam',
  'dev-ranking',
  'staging-ranking',
  'staging-speaking',
  'ranking',
  'speaking',
  'report',
  'staging-report',
  'admin',
  'staging-admin',
  'admin-v2',
  'staging-admin-v2',
  '172',
  'login',
  'auth',
];
export const TEST_LEVEL_ROUTER = {
  STARTERS: '/testlevels/starters',
  IELTS: '/testlevels/ielts',
  TOEFL_PRIMARY: '/testlevels/toefl-primary',
  MOVERS: '/testlevels/movers',
  FLYERS: '/testlevels/flyers',
  KET: '/testlevels/ket',
  PET: '/testlevels/pet',
  TOEFL_JUNIOR: '/testlevels/toefl-junior',
  SET_JUNIOR: '/testlevels/set-junior',
};

export const EXCLUDE_MOBILE = [
  '/checkout',
  '/webview',
  '/ielts/speaking/report/[id]',
  '/write/result/[id]',
];

export const EXCLUDE_REQUIRED_SIGN_IN = [
  '/login',
  '/signup',
  '/recover',
  '/checkout',
  '/webview',
  '/flyer-package',
  '/ielts/speaking/report/[id]',
  ROUTES.FLYER_PACKAGE,
];

export default ROUTES;
