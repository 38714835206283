import { useContext } from 'react';
import context from './preRouteContext';

export default function usePreRoute() {
  const value = useContext(context);
  if (value === undefined) {
    throw new Error(
      `${usePreRoute.name} must be used within a ${context.displayName || 'context'} Provider`,
    );
  }

  return value;
}
