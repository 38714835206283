import toast from '@flyer/components/src/toast/toast';
import { isServer } from '@flyer/utils';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import Button from '@/components/common/Button';
import { EXCLUDE_MOBILE } from '@/lib/routes';

export default function MobileProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation('common');
  const router = useRouter();

  const handleClick = useCallback(() => {
    if (isServer()) {
      return;
    }

    const deeplink = document.createElement('a');
    deeplink.href = `https://link.flyer.vn/?path=${window.location.pathname.replace('/exam', '')}`;
    deeplink.style.display = 'none';
    document.body.append(deeplink);

    deeplink.click();

    setTimeout(() => {
      window.location.href = 'https://flyer.dynalinks.app/exam';
    }, 500);

    // window.location.href =
    //   window.location.pathname === '/exam'
    //     ? 'https://flyerus.page.link/flyer'
    //     : `https://flyerus.page.link/flyer?link=${
    //         window.location.origin + window.location.pathname
    //       }`;
  }, []);

  const handleOpenApp = useCallback(() => {
    toast.warning(
      <div className="flex justify-between items-center">
        <p className="w-[70%] font-normal">{t('label.open_app_desc')}</p>
        <Button className="p-1 w-[85px]" onClick={handleClick}>
          {t('label.open_app_action')}
        </Button>
      </div>,
      {
        title: t('label.open_app_title'),
        important: false,
        id: 'alert_open_app',
        duration: 15_000,
      },
    );
  }, [t, handleClick]);

  useEffect(() => {
    const isExcludedRoute = EXCLUDE_MOBILE.some((item) => router.pathname.startsWith(item));
    if (isExcludedRoute) {
      return;
    }

    const { userAgent, maxTouchPoints } = window.navigator;

    if (
      /iphone|ios/i.test(userAgent) &&
      maxTouchPoints > 2 &&
      !(/macintosh/i.test(userAgent) && maxTouchPoints > 2) &&
      !/ipad/i.test(userAgent)
    ) {
      handleOpenApp();
    } else if (
      /android/i.test(userAgent) &&
      !/(android(?!.*mobile))/i.test(userAgent) &&
      maxTouchPoints > 2
    ) {
      handleOpenApp();
    }
  }, [handleOpenApp, router.pathname]);

  return children;
}
