import { graphql } from '@/__generated__';
import { EXAM_US_URL, ORIGINAL_DOMAINS } from '@/lib/routes';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

interface Props {
  children: React.ReactNode;
}

export const PartnerDomainQuery = graphql(`
  query PartnerDomainQuery($domain: String!) {
    partnerDomain(domain: $domain) {
      id
    }
  }
`);

function DomainNotFoundRedirection({ children }: Props) {
  const subdomain = useMemo(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.hostname !== 'localhost' &&
      window.location.hostname.includes('flyer.us')
    ) {
      const parts = window.location.hostname.split('.');
      const sub = parts[0];
      if (sub && !ORIGINAL_DOMAINS.includes(sub)) {
        return sub;
      }
    }
    return null;
  }, []);

  const { loading } = useQuery(PartnerDomainQuery, {
    variables: {
      domain: subdomain!,
    },
    context: { v2: true },
    skip: !subdomain,
    onCompleted: (rs) => {
      if (!rs?.partnerDomain) {
        window.location.href = EXAM_US_URL;
      }
    },
  });

  // useEffect(() => {
  //   if (
  //     typeof window !== 'undefined' &&
  //     window.location.hostname !== 'localhost' &&
  //     window.location.hostname.includes('flyer.us')
  //   ) {
  //     const parts = window.location.hostname.split('.');
  //     const subdomain = parts[0];
  //
  //     if (subdomain && !ORIGINAL_DOMAINS.includes(subdomain as string)) {
  //       void check({
  //         variables: {
  //           domain: subdomain,
  //         },
  //         onCompleted: (data) => {
  //           console.log(data);
  //           if (!data.checkForDomain.exist) {
  //             window.location.href = EXAM_US_URL;
  //           }
  //         },
  //       });
  //     }
  //   }
  // }, [check]);

  return loading ? <div /> : <>{children}</>;
}

export default DomainNotFoundRedirection;
