import { useCallback, useRef, useMemo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import context, { PreRoute } from './preRouteContext';

export default function PreRouteProvider({ children }: { children: React.ReactNode }) {
  const { asPath, back, push, pathname, query } = useRouter();
  const [history, setHistory] = useState<string[]>(['/']);

  const curRef = useRef<string>();
  const prevRef = useRef<string>();

  const backRouter = useCallback(
    (fallbackRoute?: string) => {
      // eslint-disable-next-line no-plusplus
      for (let i = history.length - 2; i >= 0; i--) {
        const route = history[i];
        if (!route?.includes('#') && route !== pathname) {
          void push(route as unknown as URL);
          const newHistory = history.slice(0, i);
          setHistory(newHistory);
          return;
        }
      }
      if (fallbackRoute) {
        void push(fallbackRoute);
      }
    },
    [history, pathname, push],
  );

  const getPreRoute: PreRoute['getPreRoute'] = useCallback(() => {
    return prevRef.current;
  }, []);

  const goBackOrHome: PreRoute['goBackOrHome'] = useCallback(() => {
    if (prevRef.current) {
      back();
    } else {
      void push('/');
    }
  }, [back, push]);

  const value = useMemo(() => {
    return { getPreRoute, goBackOrHome, backRouter };
  }, [backRouter, getPreRoute, goBackOrHome]);

  if (asPath !== curRef.current) {
    prevRef.current = curRef.current;
    curRef.current = asPath;
  }

  useEffect(() => {
    if (!(asPath.includes('?') && asPath.includes('='))) {
      setHistory((previous) => [...previous, asPath]);
    }
  }, [asPath, query]);

  return <context.Provider value={value}>{children}</context.Provider>;
}
