'use client';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { atom, useAtomValue, createStore, Provider } from 'jotai';
import { atomFamily } from 'jotai/utils';

const modalsStore = createStore();

interface ModalComponentProps {
  open?: boolean;
  unmounted?: boolean;
  priority?: number;
  onClose?: (open: boolean) => void;
}

interface ModalState extends ModalComponentProps {
  key: string;
  props?: object;
}

type ModalComponentType = React.ComponentType<ModalComponentProps>;

const getModalKey = (() => {
  let count = -1;
  const m = new Map<ModalComponentType, number>();

  return (Component: ModalComponentType) => {
    const v = m.get(Component);
    if (v || v === 0) {
      return `${v}`;
    }

    count += 1;
    m.set(Component, count);

    return `${count}`;
  };
})();

const modalsAtom = atom(new Map<ModalComponentType, ModalState>());
const modalStatesAtom = atomFamily((Component: ModalComponentType) =>
  atom(
    (get) => get(modalsAtom).get(Component),
    (get, set, state: Omit<ModalState, 'key'>) => {
      const m = get(modalsAtom);
      const key = getModalKey(Component);
      if (state.unmounted) {
        m.delete(Component);
      } else {
        m.set(Component, { ...state, key });
      }
      set(modalsAtom, new Map(m));
    },
  ),
);

function useModal<P extends ModalComponentProps>(
  Component: React.ComponentType<P>,
  props?: Omit<P, keyof ModalComponentProps> & Partial<ModalComponentProps>,
) {
  const propsRef = useRef(props);
  propsRef.current = props;

  const modalAtom = useMemo(() => modalStatesAtom(Component as ModalComponentType), [Component]);

  const hideModal = useCallback(() => {
    const { open } = propsRef.current || {};

    if (open === undefined || open === false) {
      modalsStore.set(modalAtom, { open: false });
    }
  }, [modalAtom]);

  const showModal = useCallback(
    (openProps?: Omit<P, keyof ModalComponentProps> & Partial<ModalComponentProps>) => {
      const { open, onClose, priority, ...componentProps } = propsRef.current || {};

      if (open === undefined || open === true) {
        modalsStore.set(modalAtom, {
          open: true,
          onClose: open === undefined ? hideModal : onClose,
          priority,
          props: { ...componentProps, ...openProps },
        });
      }
    },
    [hideModal, modalAtom],
  );

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    return modalsStore.sub(modalAtom, () => {
      setIsOpen(!!modalsStore.get(modalAtom)?.open);
    });
  }, [modalAtom]);

  const toggleModal = useCallback(
    (openProps?: Omit<P, keyof ModalComponentProps> & Partial<ModalComponentProps>) => {
      if (isOpen) {
        hideModal();
      } else {
        showModal(openProps);
      }
    },
    [hideModal, isOpen, showModal],
  );

  useEffect(() => {
    return () => {
      modalsStore.set(modalAtom, { unmounted: propsRef.current?.unmounted ?? true, open: false });
    };
  }, [modalAtom]);

  const open = propsRef.current?.open;

  useEffect(() => {
    if (open === undefined) {
      return;
    }

    if (!isOpen && open) {
      showModal();
      return;
    }

    if (isOpen && !open) {
      hideModal();
    }
  }, [hideModal, isOpen, open, showModal]);

  return { showModal, hideModal, toggleModal, isOpen };
}

function ModalContainerInner() {
  const modals = useAtomValue(modalsAtom);
  const entries = useMemo(() => [...modals.entries()], [modals]);
  const OpenedModal = useMemo(() => {
    let currentComponent;
    let currentPriority = 0;
    for (const [Component, { open, priority = 0 }] of entries) {
      if (open && (!currentComponent || priority > currentPriority)) {
        currentComponent = Component;
        currentPriority = priority;
      }
    }
    return currentComponent;
  }, [entries]);

  return (
    <>
      {entries.map(([Component, { props, key, onClose }]) => {
        return (
          <Component key={key} open={Component === OpenedModal} onClose={onClose} {...props} />
        );
      })}
    </>
  );
}

export function ModalContainer() {
  return (
    <Provider store={modalsStore}>
      <ModalContainerInner />
    </Provider>
  );
}

export default useModal;
