import { m, HTMLMotionProps } from 'framer-motion';
import Image, { StaticImageData } from 'next/image';
import React, { forwardRef, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { buttonAnimation } from '@/lib/animation';
import IconNotiActive from '@/assets/images/noti-active.png';

export type ButtonColorScheme =
  | 'red'
  | 'purple'
  | 'purple-2'
  | 'blue'
  | 'blue-1'
  | 'orange'
  | 'green'
  | 'ocean-green'
  | 'orange-soda'
  | 'teal'
  | 'dark-orchid'
  | 'indigo';
export type ButtonProps = Omit<HTMLMotionProps<'button'>, 'ref'> & {
  children?: React.ReactNode;
  colorScheme?: ButtonColorScheme;
  className?: string;
  variant?: keyof typeof VARIANTS;
  size?: keyof typeof SIZES;
  shape?: 'rectangle' | 'square' | 'hex';
  Icon?: React.FC<JSX.IntrinsicElements['svg']>;
  style?: object;
  animation?: object | boolean | null;
  isActive?: boolean;
  classNameIcon?: string;
  icon?: StaticImageData;
  classNameText?: string;
  isLoading?: boolean;
  showNotifyActive?: boolean;
  iconPosition?: keyof typeof ICON_POSITION;
};

const ROUNDED = {
  solid: 'rounded-[4px]',
  polish: 'rounded-[8px]',
  alien: 'rounded-0',
};

const VARIANTS = {
  solid: 'btn-solid',
  polish: 'btn-polish',
  alien: 'btn-alien',
};

const SHAPES = {
  square: 'w-[30px] h-[27px] sm:w-[45px] sm:h-[45px]',
  rectangle: 'w-full',
  hex: 'w-full',
};

const SIZES = {
  default: 'text-[12px]',
  small: 'p-1 text-[9px]',
  large: 'p-4',
};

const ICON_POSITION = {
  left: 'left-[-37px]',
  right: 'right-[-37px]',
};

export default forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    children,
    className,
    variant = 'solid',
    shape = 'rectangle',
    Icon,
    colorScheme = 'purple',
    style,
    size = 'default',
    animation,
    isActive,
    classNameIcon,
    icon,
    classNameText,
    isLoading,
    iconPosition = 'left',
    showNotifyActive = false,
    ...props
  },
  ref,
) {
  const mergeClassName = twMerge(
    `${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'} ${SIZES[size]} ${VARIANTS[variant]} ${
      ROUNDED[variant]
    } ${SHAPES[shape]} flex justify-center items-center align-middle relative outline-none`,
    'transition-all duration-75',
    className,
  );

  const getAnimate = useCallback(() => {
    if (animation !== undefined) {
      return typeof animation === 'object' ? animation : {};
    }
    if (isLoading) {
      return {};
    }
    return buttonAnimation;
  }, [animation, isLoading]);

  return (
    <m.button
      ref={ref}
      type="button"
      className={mergeClassName}
      style={style}
      data-color={colorScheme}
      data-variant={variant}
      {...getAnimate()}
      data-active={isActive}
      {...props}
      disabled={props.disabled || isLoading}
    >
      {Icon && (
        <div
          className={`absolute left-[-30px] sm:left-[-14px] sm:top-[-5px] top-[-8px]   ${
            classNameIcon || ''
          }`}
        >
          <Icon className="w-full icon-button" />
        </div>
      )}
      {icon && (
        <div
          className={twMerge(
            `absolute ${ICON_POSITION[iconPosition]} top-[-8px] z-[1]`,
            classNameIcon,
          )}
        >
          <Image src={icon} alt="Icon" className="w-full" />
        </div>
      )}
      {showNotifyActive && (
        <div
          className={twMerge(
            `absolute top-[-9%] z-[1] w-[20px] sm:w-[8px] md:w-[10px] lg:w-[15px] xl:w-[20px] right-0`,
          )}
        >
          <Image src={IconNotiActive} alt="Icon" className="w-full" />
        </div>
      )}
      {variant === 'solid' && (
        <div className="overlay absolute left-0 top-0 w-full h-1/2 rounded-[inherit] " />
      )}
      <div
        className={`text-white normal w-full h-full font-extrabold relative btn-content ${
          classNameText || ''
        }`}
      >
        <span>
          <div className={`${isLoading ? 'opacity-[0] ' : ''}`}>{children}</div>
        </span>
      </div>
      {isLoading && (
        <div className="absolute inset-0 flex justify-center items-center bg-[rgba(0,0,0,0.3)] rounded-[inherit]">
          <div className="dot-pulse" />
        </div>
      )}
    </m.button>
  );
});
