// @ts-check

// const workaround = require('next-translate/lib/cjs/plugin/utils');
// workaround.defaultLoader = '(l, n) => import(`locales/${l}/${n}.json`).then(m => m.default)';

/**
 * @type {import('next-translate').I18nConfig} config
 */
module.exports = {
  locales: ['vi', 'en'],
  defaultLocale: 'vi',
  domains: [
    {
      domain: process.env.EXAM_US_DOMAIN,
      defaultLocale: 'en',
    },
  ],
  localeDetection: false,
  pages: {
    '*': ['common', 'error', 'modal', 'popover', 'userError'],
    'rgx:(/login|/signup|/recover|/verify-account|/signup-new)$': ['auth'],
    'rgx:(/create-profile|/profiles|/edit-profile|/update-profile|/edit-profile/info-user)$': [
      'account',
    ],
    'rgx:(/check-point|/test-results)': ['exam-old'],
    'rgx:(/checkout)': ['checkout'],
    'rgx:^/ielts': ['ielts'],
    '/[lang]': ['home'],
    '/[lang]/second-page': ['home'],
  },
  // @ts-ignore
  loadLocaleFrom: (lang, ns) => {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return Promise.resolve(require(`./src/locales/${lang}/${ns}.json`));
  },
  logBuild: true,
};
