import { get, isPlainObject } from 'lodash-es';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { usePreRoute } from '@/providers/pre-route';
import context from '@/providers/message/messageContext';
import { useSetAtom } from 'jotai';
import { isFromChallengeAtom } from '@/store/message';

export default function MessageProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const { goBackOrHome } = usePreRoute();
  const setIsFromChallengeAtom = useSetAtom(isFromChallengeAtom);
  useEffect(() => {
    const handler = (event: MessageEvent) => {
      if (typeof event.data === 'string') {
        const dataSplit = event.data.split('|');
        // check-point-page

        if (event.data === 'CHECK_POINT_EXIT') {
          if (router.query?.url_back) {
            return router.push(router.query.url_back as string);
          }
          return router.push('/');
        }

        if (dataSplit[0] === 'TEST_RESULT') {
          return router.push(`/test-results/${dataSplit[1] as string}/result`);
        }
        if (event.data === 'GO_BACK_OR_HOME') {
          return goBackOrHome();
        }
        // test result

        if (event.data === 'REVIEW') {
          return router.push(`/test-results/${router.query.id as string}`);
        }

        if (event.data === 'REDIRECT') {
          return router.push(
            `/signup/?auth_redirect_url=${encodeURI(
              window.location.pathname.replace('/result', '').replace('/exam', ''),
            )}`,
          );
        }
        if (event.data === 'REDIRECT_SIGNING') {
          return router.push(
            `/login/?auth_redirect_url=${encodeURI(
              window.location.pathname.replace('/result', '').replace('/exam', ''),
            )}`,
          );
        }
        if (dataSplit[0] === 'COPY_URL') {
          return navigator.clipboard.writeText(dataSplit[1] as string);
        }

        if (event.data === 'HOME') {
          return router.push('/');
        }
        if (event.data === 'LOGIN') {
          return router.push({
            pathname: '/login',
            query: { auth_redirect_url: router.asPath },
          });
        }
      }
      if (isPlainObject(event.data)) {
        const key = get(event.data, 'key');
        const value = get(event.data, 'value') as string;
        if (key && key === 'CREATE_CHALLENGE_CHECKPOINT_POPUP') {
          const checkPointId = get(event.data, 'checkPointId') as string;
          const testLevel = get(event.data, 'testLevelId') as string;
          return router.push(`/create-challenge/?cp=${checkPointId}&lv=${testLevel}`);
        }
        if (key && key === 'GO_SPEAKING_LISTING') {
          return router.push(`/speaking-listing/${value}`);
        }
        if (key === 'GO_SPEAKING_DETAIL') {
          return window.open(value, '_blank');
        }
        if (key === 'CREATE_CHALLENGE_BY_CODE') {
          return router.push(`/join-challenge/${value}`);
        }
        if (key === 'VIEW_LEADER_BOARD') {
          return router.push(`/leader-board-challenge/${value}`);
        }
        if (key === 'JOIN_BY_CODE_ANONYMUST') {
          return router.push(`/join-challenge/join/join-by-code/${value}`);
        }
        if (key === 'CREATE_CHALLENGE_WITH_CHECKPOINT') {
          return router.push(`/create-challenge/${value}`);
        }
        if (key === 'CREATE_CHALLENGE') {
          return router.push(`/create-challenge`);
        }
        if (key === 'GO_TO_CHECKPOINT') {
          if (value.includes('challengeCode')) {
            setIsFromChallengeAtom(true);
          }
          return router.push(`/check-point/${value}`);
        }
        if (key === 'ON_COPY') {
          return navigator.clipboard.writeText(value);
        }
        if (key === 'HOME_WITH_PARAM') {
          return router.push(`/${value}`);
        }
      }
    };
    if (router.isReady) {
      window.addEventListener('message', handler);
    }
    return () => window.removeEventListener('message', handler);
  }, [goBackOrHome, router, setIsFromChallengeAtom]);
  return <context.Provider value>{children}</context.Provider>;
}
