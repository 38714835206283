import { useEffect, useState } from 'react';
import { getRemoteConfig, fetchAndActivate, getAll } from 'firebase/remote-config';
import { app } from '@/lib/firebase';
import context, { RemoteConfig } from './remoteConfigContext';

export default function RemoteConfigProvider({ children }: { children: React.ReactNode }) {
  const [values, setValues] = useState<RemoteConfig>({});

  useEffect(() => {
    const remoteConfig = getRemoteConfig(app);
    const interval = process.env.NEXT_PUBLIC_REMOTE_CONFIG_FETCH_INTERVAL_MILLIS;
    if (interval) {
      remoteConfig.settings.minimumFetchIntervalMillis = Number.parseInt(interval, 10);
    }
    void fetchAndActivate(remoteConfig).then(() => {
      const val: RemoteConfig = {};
      for (const [key, value] of Object.entries(getAll(remoteConfig))) {
        const v = value.asString();
        try {
          val[key] = JSON.parse(v);
        } catch {
          val[key] = v;
        }
      }
      return setValues(val);
    });
  }, []);

  return <context.Provider value={values}>{children}</context.Provider>;
}
