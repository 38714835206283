export const phoneNumberRegex = /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/;
export const usernameRegex = /^[\d_a-z]{3,150}$/;

export const aiColorOption = {
  incorrect: '#FF4842',
  correct: '#5BA135',
  warning: '#FFC107',
};

export const LINK_URL = {
  FORM_PARTNER: 'https://forms.gle/XF94wPwZeQvHTw2n7',
  FORM_COMMUNITY: 'https://forms.gle/XF94wPwZeQvHTw2n7',
  REPORT_BUG: 'https://forms.gle/DxdZqfgSirYzf1vW8',
};
