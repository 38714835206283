import slugify from 'slugify';

export const isServer = (): boolean => typeof window === 'undefined';
export const isClient = (): boolean => typeof window !== 'undefined';

export function toSlugParam(name: string, slug: string): string {
  return `${slugify(name)}.${slug}`;
}

export function fromSlugParam(param?: string | string[]): string {
  if (!param) {
    return '';
  }

  const parts = Array.isArray(param) ? param : param.split('.');
  if (parts.length === 1) {
    return parts[0] as string;
  }

  return parts.at(-1) as string;
}

export function isMobile(allowIpad = false): boolean {
  const toMatch = [/android/i, /webos/i, /iphone/i, /ipod/i, /blackberry/i, /windows phone/i];
  if (typeof window === 'undefined') return false;
  return (
    toMatch.some((toMatchItem) => window.navigator.userAgent.match(toMatchItem)) ||
    !!(
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform) &&
      allowIpad
    )
  );
}

export function isUUID(id: string) {
  const uuidReg = /^[\da-f]{8}-[\da-f]{4}-[0-5][\da-f]{3}-[089ab][\da-f]{3}-[\da-f]{12}$/i;
  return uuidReg.test(id);
}

export function transformPhoneNumber(phoneNumber: string, countryCode: string) {
  let phone = phoneNumber.replaceAll(/\s/g, '').replace(countryCode, '');
  if (countryCode === '+84') {
    // remove 0 is first, replace string or Special characters
    phone = phone.replaceAll(/\W|[a-z]|[A-Z]/g, '').replace(/^0/, '');
  }

  return countryCode + phone;
}

export function handleUserErrors<F extends string>(
  userErrors: Array<{ code: string; field?: string[] | null; message: string }>,
  {
    setOperationError,
    setFieldError,
    operationKeys,
    fieldKeys,
    t,
  }: {
    setOperationError?: (message: string) => void;
    setFieldError?: (field: F, message: string) => void;
    operationKeys?: Record<string, string | (() => string)>;
    fieldKeys?: { [key in F]?: Record<string, string | (() => string)> };
    t?: (key: string, options?: { field: string }) => string;
  },
) {
  const operationErrors = [];
  const fieldErrors: { [key: string]: string[] } = {};
  for (const userError of userErrors) {
    if (!userError.field || userError.field.length === 0) {
      const operationKey = operationKeys?.[userError.code];
      const defaultOperationKey = `userError:code.operation.${userError.code}`;
      const operationMessage = operationKey
        ? typeof operationKey === 'function'
          ? operationKey()
          : t?.(operationKey) || operationKey
        : t?.(defaultOperationKey) || userError.message;

      operationErrors.push(operationMessage);
    } else if (userError.field) {
      const field = userError.field.at(-1) as F;

      if (!fieldErrors[field]) {
        fieldErrors[field] = [];
      }
      const fieldKey = fieldKeys?.[field]?.[userError.code];
      const defaultFieldKey = `userError:code.field.${userError.code}`;
      const fieldMessage = fieldKey
        ? typeof fieldKey === 'function'
          ? fieldKey()
          : t?.(fieldKey, { field }) || fieldKey
        : t?.(defaultFieldKey, { field }) || `${userError.message}`;

      fieldErrors[field]!.push(fieldMessage);
    }
  }
  if (setOperationError) {
    setOperationError(operationErrors.join('\n'));
  }
  if (setFieldError) {
    for (const [field, errors] of Object.entries(fieldErrors)) {
      setFieldError(field as F, errors.join('\n'));
    }
  }
}
