import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  wrapClassName?: string;
  textClassName?: string;
  children: React.ReactNode;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { wrapClassName, textClassName, className, children, ...rest } = props;

  return (
    <label className={twMerge('flex justify-center items-center normal-case', wrapClassName)}>
      <input
        {...rest}
        ref={ref}
        type="checkbox"
        className={twMerge(
          'aspect-square w-[1em] h-[1em] mr-[1%] bg-[#00000080] text-[#00000080] rounded-[0.1em]',
          className,
        )}
      />
      <span className={twMerge(textClassName)}>{children}</span>
    </label>
  );
});

export default Checkbox;
