import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import imageReport from '@/assets/images/button_feedback.png';
import imageReportVi from '@/assets/images/button_feedback_vi.png';
import { LINK_URL } from '@/lib/constant';

function ReportBug() {
  const router = useRouter();
  const { lang } = useTranslation();
  const isHide =
    router.route.includes('/login') ||
    router.route.includes('/signup') ||
    router.route.includes('/checkout') ||
    router.route.includes('/recover') ||
    router.route.includes('/webview');

  return (
    <div
      className={twMerge(
        'fixed right-0 xl:bottom-[3.5vw] bottom-[10%] cursor-pointer z-[100]',
        isHide ? 'opacity-0 pointer-events-none' : 'opacity-100',
      )}
    >
      <Link target="_blank" href={LINK_URL.REPORT_BUG}>
        <Image
          src={lang === 'en' ? imageReport : imageReportVi}
          alt="flyer report"
          className="xl:w-[2vw] w-[3vw]"
        />
      </Link>
    </div>
  );
}

export default ReportBug;
