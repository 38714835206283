'use client';

import React from 'react';
import hotToast, { ToastOptions as HotToastOptions } from 'react-hot-toast/headless';

export type ToastType = 'info' | 'error' | 'success' | 'warning';

export interface ToastOptions {
  title?: string;
  duration?: number;
  rightRender?: (t: { dismiss: () => void }) => React.ReactNode;
  important?: boolean;
  id?: string;
}

function getOptions(type: ToastType, options?: ToastOptions) {
  const opts = { ...options, type };
  if (opts.important && !opts.duration && opts.duration !== 0) {
    opts.duration = Number.POSITIVE_INFINITY;
  }
  return opts as HotToastOptions;
}

export type Message = string | JSX.Element;

const toast = {
  info: (message: Message, options?: ToastOptions) =>
    hotToast(message, getOptions('info', options)),
  error: (message: Message, options?: ToastOptions) =>
    hotToast(message, getOptions('error', options)),
  success: (message: Message, options?: ToastOptions) =>
    hotToast(message, getOptions('success', options)),
  warning: (message: Message, options?: ToastOptions) =>
    hotToast(message, getOptions('warning', options)),
  dismiss: (id: string) => hotToast.dismiss(id),
};

export default toast;
