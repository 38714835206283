import { createContext } from 'react';

export interface RemoteConfig {
  [key: string]: unknown;
  localTest?: Record<string, { classCode: string; placementTestIndex: number }>;
  enableShare?: boolean;
  enableDebug?: boolean;
  enabledNotiNetworkSlow?: boolean;
  disabledAnimationHome?: boolean;
  disabledSubmitQuestionExtend?: boolean;
}

export default createContext<RemoteConfig | undefined>(undefined);
