'use client';

import { useState, useCallback, useMemo, useEffect, useRef, cloneElement } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  useFloating,
  useInteractions,
  useHover,
  useClick,
  useDismiss,
  useRole,
  FloatingPortal,
  flip,
  Placement,
  shift,
  offset,
  // inline,
  arrow,
  hide,
  autoUpdate,
} from '@floating-ui/react';
import { AnimatePresence, m } from 'framer-motion';
import fillRef from '../utils/fillRef';

type ArrowPlacement = 'top' | 'right' | 'bottom' | 'left';

function getArrowPosition(size: number, placement: ArrowPlacement) {
  const parentPlacements = {
    top: {
      bottom: -size / 2,
      transform: 'rotate(-135deg)',
    },
    right: {
      left: -size / 2,
      transform: 'rotate(-45deg)',
    },
    bottom: {
      top: -size / 2,
      transform: 'rotate(45deg)',
    },
    left: {
      right: -size / 2,
      transform: 'rotate(135deg)',
    },
  };

  return parentPlacements[placement];
}

export interface PopoverProps {
  trigger?: 'click' | 'hover' | 'always' | 'none';
  onlyShowOnEllipsis?: boolean;
  ellipsisRow?: number;
  // for trigger is click only and the number is in ms
  hideAfterToggleOpen?: number;
  placement?: Placement;
  autoFlip?: boolean;
  fallbackPlacements?: Array<Placement>;
  offset?: number;
  showArrow?: boolean;
  arrowSize?: number;
  arrowClassName?: string;
  borderSize?: number;
  content: React.ReactNode;
  contentClassName?: string;
  children: JSX.Element;
  zIndex?: number;
  containerClassName?: string;
}

export default function Popover(props: PopoverProps) {
  const {
    trigger = 'hover',
    hideAfterToggleOpen,
    placement: placementProp = 'top',
    autoFlip = true,
    fallbackPlacements,
    showArrow = true,
    arrowSize = 8,
    arrowClassName,
    borderSize = 2,
    offset: offsetProp = 1,
    content,
    contentClassName,
    children,
    onlyShowOnEllipsis = false,
    ellipsisRow = 1,
    zIndex = 20,
    containerClassName,
  } = props;

  const [open, setOpen] = useState(false);

  const triggerRef = useRef<HTMLElement>(null);

  const hideTimeOutRef = useRef<number>();

  const maybeSetOpen = useCallback(
    (o: boolean) => {
      if (onlyShowOnEllipsis && o) {
        const target = triggerRef.current;
        if (
          target &&
          (target.offsetWidth < target.scrollWidth ||
            (ellipsisRow > 1 && target.offsetHeight + 1 < target.scrollHeight))
        ) {
          setOpen(true);
        }
        return;
      }

      if (trigger === 'click' && o && hideAfterToggleOpen) {
        if (hideTimeOutRef.current) {
          window.clearTimeout(hideTimeOutRef.current);
        }
        hideTimeOutRef.current = window.setTimeout(() => setOpen(false), hideAfterToggleOpen);
      }

      setOpen(o);
    },
    [onlyShowOnEllipsis, trigger, hideAfterToggleOpen, ellipsisRow],
  );

  const arrowRef = useRef<HTMLDivElement>(null);

  const middleware = useMemo(() => {
    const calculatedOffset = (showArrow ? arrowSize + 2 : 0 + borderSize) / 2 + offsetProp;

    const list = [/* inline(), */ arrow({ element: arrowRef }), offset(calculatedOffset), shift()];
    if (autoFlip) {
      list.push(flip({ fallbackPlacements }));
    }
    list.push(hide());

    return list;
  }, [arrowSize, autoFlip, borderSize, offsetProp, showArrow, fallbackPlacements]);

  const { x, y, refs, strategy, context, middlewareData, placement } = useFloating({
    open,
    onOpenChange: maybeSetOpen,
    placement: placementProp,
    whileElementsMounted: autoUpdate,
    middleware,
  });
  const { x: arrowX, y: arrowY } = middlewareData.arrow ?? {};
  const { referenceHidden } = middlewareData.hide ?? {};
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { enabled: trigger === 'click' }),
    useHover(context, { enabled: trigger === 'hover' }),
    useDismiss(context, { enabled: trigger === 'click' }),
    useRole(context, { role: 'tooltip' }),
  ]);

  useEffect(() => {
    const triggerEl = triggerRef.current;
    if (triggerEl && trigger === 'click') {
      const referenceProps = getReferenceProps() as {
        onClick: EventListenerOrEventListenerObject;
        onKeyDown: EventListenerOrEventListenerObject;
        onKeyUp: EventListenerOrEventListenerObject;
        onMouseDown: EventListenerOrEventListenerObject;
        onPointerDown: EventListenerOrEventListenerObject;
      };

      triggerEl.addEventListener('click', referenceProps.onClick);
      triggerEl.addEventListener('keydown', referenceProps.onKeyDown);
      triggerEl.addEventListener('keyup', referenceProps.onKeyUp);
      triggerEl.addEventListener('mousedown', referenceProps.onMouseDown);
      triggerEl.addEventListener('pointerdown', referenceProps.onPointerDown);

      return () => {
        triggerEl.removeEventListener('click', referenceProps.onClick);
        triggerEl.removeEventListener('keydown', referenceProps.onKeyDown);
        triggerEl.removeEventListener('keyup', referenceProps.onKeyUp);
        triggerEl.removeEventListener('mousedown', referenceProps.onMouseDown);
        triggerEl.removeEventListener('pointerdown', referenceProps.onPointerDown);
      };
    }

    return () => {};
  }, [getReferenceProps, trigger]);

  useEffect(() => {
    const triggerEl = triggerRef.current;
    if (triggerEl && trigger === 'hover') {
      const referenceProps = getReferenceProps() as {
        onMouseMove: EventListenerOrEventListenerObject;
        onPointerDown: EventListenerOrEventListenerObject;
        onPointerEnter: EventListenerOrEventListenerObject;
      };

      triggerEl.addEventListener('mousemove', referenceProps.onMouseMove);
      triggerEl.addEventListener('pointerdown', referenceProps.onPointerDown);
      triggerEl.addEventListener('pointerenter', referenceProps.onPointerEnter);

      return () => {
        triggerEl.removeEventListener('mousemove', referenceProps.onMouseMove);
        triggerEl.removeEventListener('pointerdown', referenceProps.onPointerDown);
        triggerEl.removeEventListener('pointerenter', referenceProps.onPointerEnter);
      };
    }

    return () => {};
  }, [getReferenceProps, trigger]);

  const childrenRef = useCallback(
    (node: HTMLElement | null) => fillRef(node, triggerRef, refs.setReference),
    [refs.setReference],
  );

  const childrenProps = children.props as { className?: string; style?: React.CSSProperties };

  const [animating, setAnimating] = useState(false);
  const onAnimationStart = useCallback(() => {
    setAnimating(true);
  }, []);
  const onExitComplete = useCallback(() => {
    setAnimating(false);
  }, []);

  useEffect(() => {
    if (trigger === 'always') {
      maybeSetOpen(true);
    } else if (trigger === 'none') {
      maybeSetOpen(false);
    }
  }, [trigger, maybeSetOpen]);

  return (
    <>
      {cloneElement(children, {
        ...childrenProps,
        ref: childrenRef,
        className: twMerge(
          onlyShowOnEllipsis &&
            (ellipsisRow === 1
              ? 'truncate'
              : '[display:-webkit-inline-box] [-webkit-box-orient:vertical] overflow-hidden'),
          childrenProps.className,
        ),
        style:
          ellipsisRow === 1
            ? childrenProps.style
            : { ...childrenProps.style, WebkitLineClamp: ellipsisRow },
      })}
      {(open || animating) && (
        <FloatingPortal>
          <AnimatePresence onExitComplete={onExitComplete}>
            {open && (
              <m.div
                onAnimationStart={onAnimationStart}
                ref={refs.setFloating}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  // Positioning styles
                  visibility: referenceHidden ? 'hidden' : 'visible',
                  position: strategy,
                  top: y === Number.POSITIVE_INFINITY || !y ? 0 : y,
                  left: x === Number.POSITIVE_INFINITY || !x ? 0 : x,
                  padding: borderSize,
                  zIndex,
                }}
                className={twMerge(
                  'relative w-max rounded-md bg-[image:linear-gradient(to_right_bottom,#75F8D2,#32B7EA)]',
                  containerClassName,
                )}
                {...getFloatingProps()}
              >
                <div
                  className={twMerge(
                    'rounded-[inherit] bg-[#0134A7] w-full h-full py-2 px-3 text-white',
                    contentClassName,
                  )}
                >
                  {content}
                </div>
                {showArrow && (
                  <div
                    ref={arrowRef}
                    className="absolute bg-[image:linear-gradient(to_right_bottom,#75F8D2,#32B7EA)] -z-[1]"
                    style={{
                      left: arrowX == null ? undefined : `${arrowX}px`,
                      top: arrowY == null ? undefined : `${arrowY}px`,
                      height: arrowSize,
                      width: arrowSize,
                      ...getArrowPosition(arrowSize, placement.split('-')[0] as ArrowPlacement),
                    }}
                  >
                    <div
                      className={twMerge('h-full w-full bg-[#0134A7]', arrowClassName)}
                      style={{
                        transform: `translate(${borderSize}px,${borderSize}px)`,
                      }}
                    />
                  </div>
                )}
              </m.div>
            )}
          </AnimatePresence>
        </FloatingPortal>
      )}
    </>
  );
}
