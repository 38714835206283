import { createElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { Popover } from '../popover';

type Tag = keyof JSX.IntrinsicElements;

export type TextFitProps<K extends Tag> = JSX.IntrinsicElements[K] & {
  fontSize: number;
  children: React.ReactNode;
  lineHeight?: number;
  rows?: number;
  as?: K;
  style?: React.CSSProperties;
  className?: string;
  svgStyle?: React.CSSProperties;
  svgClassName?: string;
  tooltipClassName?: string;
};

export default function TextFit<K extends Tag = 'p'>(props: TextFitProps<K>) {
  const {
    fontSize,
    lineHeight = 1,
    rows = 1,
    as = 'p',
    tooltipClassName,
    svgClassName,
    className,
    svgStyle,
    style,
    children,
    ...rest
  } = props;

  const height = Math.round((fontSize * lineHeight * rows + Number.EPSILON) * 100) / 100;

  return (
    <svg
      viewBox={`0 0 100 ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge('w-full', svgClassName)}
      style={svgStyle}
    >
      <foreignObject width="100" height={height}>
        {rows > 1 ? (
          createElement(
            as,
            {
              ...rest,
              xmlns: 'http://www.w3.org/1999/xhtml',
              className: twMerge('h-full flex items-center justify-center', className),
              style: { ...style, fontSize, lineHeight },
            },
            <Popover
              content={children}
              contentClassName={tooltipClassName}
              onlyShowOnEllipsis
              ellipsisRow={rows}
            >
              <span>{children}</span>
            </Popover>,
          )
        ) : (
          <Popover content={children} contentClassName={tooltipClassName} onlyShowOnEllipsis>
            {createElement(
              as,
              {
                ...rest,
                xmlns: 'http://www.w3.org/1999/xhtml',
                className: twMerge('block text-center', className),
                style: { ...style, fontSize, lineHeight },
              },
              children,
            )}
          </Popover>
        )}
      </foreignObject>
    </svg>
  );
}
