import { isServer } from '@flyer/utils';

const isFlyerUs = (): boolean => !isServer() && window?.location?.hostname?.endsWith('flyer.us');

export const isDev = process.env.NODE_ENV === 'development';

export const redisConfig = {
  host: process.env.REDIS_HOST,
  password: process.env.REDIS_PASSWORD,
  port: process.env.REDIS_PORT ? Number.parseInt(process.env.REDIS_PORT, 10) : 6379,
};

export const ENVIRONMENT = {
  PUBLIC_ANONYMOUS_LEARNER_PROFILE_ID:
    process.env.NEXT_PUBLIC_ANONYMOUS_LEARNER_PROFILE_ID || 'anonymous_learner_profile_id',
  PUBLIC_COOKIE_PROFILE_ID: process.env.NEXT_PUBLIC_COOKIE_PROFILE_ID || 'profile_id',
  PUBLIC_RANKING_URL: () => {
    const isUs = isFlyerUs();

    return isUs
      ? process.env.NEXT_PUBLIC_RANKING_URL || 'https://ranking.flyer.us'
      : process.env.NEXT_PUBLIC_RANKING_VN_URL || 'https://ranking.flyer.vn';
  },
  PUBLIC_SPEAKING_URL: () => {
    const isUs = isFlyerUs();

    return isUs
      ? process.env.NEXT_PUBLIC_BASE_SPEAKING_URL_US || 'https://speaking.flyer.us'
      : process.env.NEXT_PUBLIC_BASE_SPEAKING_URL || 'https://speaking.flyer.vn';
  },
  PUBLIC_BASE_MEDIA_URL: process.env.NEXT_PUBLIC_BASE_MEDIA_URL || 'https://statics.flyer.vn',
  PUBLIC_DOMAIN_FREE: process.env.NEXT_PUBLIC_DOMAIN_FREE || ['localhost', 'staging.exam'],
  PUBLIC_CHECKPOINT_FREE: [],
  DSN_SENTRY: process.env.NEXT_PUBLIC_DSN_SENTRY,
  IS_DEV:
    process.env.NEXT_PUBLIC_ENVIRONMENT?.toString() === 'development' ||
    process.env.NEXT_PUBLIC_ENVIRONMENT?.toString() === 'local',
};

export const HIDE_SCHOOL_DOMAINS = [
  'exam.flyer.us',
  'spain.flyer.us',
  'dev.exam.flyer.us',
  'staging.exam.flyer.us',
  'localhost:3003',
];
