import { m, HTMLMotionProps } from 'framer-motion';
import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { omit } from 'lodash-es';
import LeftCorner from '@/assets/svg/ic-modal-button-left-corner.svg';
import RightCorner from '@/assets/svg/ic-modal-button-right-corner.svg';
import Loading from '@/components/common/Loading';

type ButtonProps = Omit<HTMLMotionProps<'button'>, 'ref'> & {
  children?: React.ReactNode;
  size?: keyof typeof SIZES;
  colorScheme?: 'orange' | 'gray' | 'purple' | 'blue' | 'pink';
  wrapClassName?: string;
  className?: string;
  childClassName?: string;
  isLoading?: boolean;
};

const BUTTON_CONTAINER = {
  orange: 'from-[#FDDBD4] to-[#BF381C]',
  gray: 'from-[#F2F2F2] to-[#393939]',
  purple: 'from-[#CA95F6] to-[#711C94]',
  blue: 'from-[#8FDDFF] to-[#005CAB]',
  pink: 'from-[#FFC5F0] to-[#D2257C]',
};

const BUTTON_CONTENT = {
  orange: '[&>.btn-content]:from-[#F48E77] [&>.btn-content]:to-[#E36245]',
  gray: '[&>.btn-content]:from-[#BABABA] [&>.btn-content]:to-[#565656]',
  purple: '[&>.btn-content]:from-[#C161E3] [&>.btn-content]:to-[#A029C7]',
  blue: '[&>.btn-content]:from-[#4DA7FF] [&>.btn-content]:to-[#1985F6]',
  pink: '[&>.btn-content]:from-[#FF8BCD] [&>.btn-content]:to-[#FF5AAC]',
};

const LEFT_CORNER = {
  orange:
    '[&>.left-corner>svg>path:nth-child(1)]:fill-[#F8957F] [&>.left-corner>svg>path:nth-child(2)]:fill-[#FFD5B9] [&>.left-corner>svg>path:nth-child(3)]:fill-[#E66549] [&>.left-corner>svg>path:nth-child(4)]:fill-[#E66549]',
  gray: '[&>.left-corner>svg>path:nth-child(1)]:fill-[#FFFFFF] [&>.left-corner>svg>path:nth-child(2)]:fill-[#FAFAFA] [&>.left-corner>svg>path:nth-child(3)]:fill-[#474747] [&>.left-corner>svg>path:nth-child(4)]:fill-[#474747]',
  purple:
    '[&>.left-corner>svg>path:nth-child(1)]:fill-[#B14DD5] [&>.left-corner>svg>path:nth-child(2)]:fill-[#E4C4FF] [&>.left-corner>svg>path:nth-child(3)]:fill-[#711C94] [&>.left-corner>svg>path:nth-child(4)]:fill-[#711C94]',
  blue: '[&>.left-corner>svg>path:nth-child(1)]:fill-[#91DCFF] [&>.left-corner>svg>path:nth-child(2)]:fill-[#90DCFF] [&>.left-corner>svg>path:nth-child(3)]:fill-[#0057A1] [&>.left-corner>svg>path:nth-child(4)]:fill-[#0057A1]',
  pink: '[&>.left-corner>svg>path:nth-child(1)]:fill-[#FFC5F0] [&>.left-corner>svg>path:nth-child(2)]:fill-[#FFC5F0] [&>.left-corner>svg>path:nth-child(3)]:fill-[#D2257C] [&>.left-corner>svg>path:nth-child(4)]:fill-[#D2257C]',
};

const RIGHT_CORNER = {
  orange:
    '[&>.right-corner>svg>path:nth-child(1)]:fill-[#CB300E] [&>.right-corner>svg>path:nth-child(2)]:fill-[#FFD5B9] [&>.right-corner>svg>path:nth-child(3)]:fill-[#AE1F00]',
  gray: '[&>.right-corner>svg>path:nth-child(1)]:fill-[#474747] [&>.right-corner>svg>path:nth-child(2)]:fill-[#9C9C9C] [&>.right-corner>svg>path:nth-child(3)]:fill-[#585858]',
  purple:
    '[&>.right-corner>svg>path:nth-child(1)]:fill-[#711C94] [&>.right-corner>svg>path:nth-child(2)]:fill-[#E4C4FF] [&>.right-corner>svg>path:nth-child(3)]:fill-[#711C94]',
  blue: '[&>.right-corner>svg>path:nth-child(1)]:fill-[#0057A1] [&>.right-corner>svg>path:nth-child(2)]:fill-[#90DCFF] [&>.right-corner>svg>path:nth-child(3)]:fill-[#0057A1]',
  pink: '[&>.right-corner>svg>path:nth-child(1)]:fill-[#D2257C] [&>.right-corner>svg>path:nth-child(2)]:fill-[#FFC5F0] [&>.right-corner>svg>path:nth-child(3)]:fill-[#D2257C]',
};

const SIZES = {
  sm: {
    button: 'px-6 py-1 sm:text-[14px] sm:text-[12px]',
    corner: 'w-[22px]',
  },
  md: {
    button: 'lg:px-3 sm:py-2 py-1 sm:text-[14px] lg:text-[16px] p-1 text-[13px]',
    corner: 'lg:w-[32px] w-[20px]',
  },
  lg: {
    button: 'sm:px-6 sm:py-3 sm:text-[20px] py-3 px-2 text-[14px]',
    corner: 'w-[32px]',
  },
  xl: { button: 'px-6 py-4 text-[25px]', corner: 'w-[32px]' },
};

export default forwardRef<HTMLButtonElement, ButtonProps>(function ModalButton(
  {
    children,
    size = 'sm',
    colorScheme = 'blue',
    className,
    wrapClassName,
    isLoading,
    childClassName = '',
    ...props
  },
  ref,
) {
  return (
    <m.button
      type="button"
      className={twMerge(
        'relative rounded-[8px] p-[3px] flex justify-center font-semibold bg-gradient-to-b h-fit disabled:pointer-events-none disabled:cursor-not-allowed',
        BUTTON_CONTAINER[colorScheme],
        BUTTON_CONTENT[colorScheme],
        LEFT_CORNER[colorScheme],
        RIGHT_CORNER[colorScheme],
        wrapClassName,
        props.disabled &&
          `${BUTTON_CONTAINER.gray} ${BUTTON_CONTENT.gray} ${LEFT_CORNER.gray} ${RIGHT_CORNER.gray} cursor-no-drop`,
      )}
      ref={ref}
      whileTap={{
        scale: isLoading || props.disabled ? 1 : 0.8,
      }}
      {...omit(props, 'disabled')}
      disabled={isLoading || props.disabled}
    >
      <div className={`absolute left-0 top-0 ${SIZES[size].corner} left-corner`}>
        <LeftCorner />
      </div>
      <div className={`absolute bottom-0 right-0 ${SIZES[size].corner} right-corner`}>
        <RightCorner />
      </div>
      <m.div
        className={twMerge(
          SIZES[size].button,
          `w-full rounded-[inherit] transition-all btn-content bg-gradient-to-b`,
          childClassName,
        )}
        whileHover={{
          boxShadow:
            isLoading || props.disabled
              ? undefined
              : 'inset 0 0 100px 100px rgba(255, 255, 255, 0.3)',
        }}
      >
        <div className={twMerge(isLoading && 'opacity-[0]', 'whitespace-nowrap', className)}>
          {children}
        </div>
        <Loading isLoading={isLoading} />
      </m.div>
    </m.button>
  );
});
