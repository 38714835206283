function getScroll(w: Window, top = false) {
  let value = top ? w.scrollY : w.scrollX;
  const method = top ? 'scrollTop' : 'scrollLeft';
  if (typeof value !== 'number') {
    const d = w.document;
    value = d.documentElement[method];
    if (typeof value !== 'number') {
      value = d.body[method];
    }
  }
  return value;
}

// get offset of the element including scroll
export default function offset(element: HTMLElement): { left: number; top: number } {
  const rect = element.getBoundingClientRect();
  const pos = {
    left: rect.left,
    top: rect.top,
  };

  const doc = element.ownerDocument as Document & { parentWindow?: Window };
  const w = doc.defaultView || doc.parentWindow;

  if (!w) {
    return pos;
  }

  pos.left += getScroll(w);
  pos.top += getScroll(w, true);

  return pos;
}
